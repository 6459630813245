import Img1 from '../../images/gallery/img1.jpeg'
import Img2 from '../../images/gallery/img2.jpeg'
import Img3 from '../../images/gallery/img3.jpeg'
import Img4 from '../../images/gallery/img4.jpeg'
import Img5 from '../../images/gallery/img5.jpeg'
import Img6 from '../../images/gallery/img6.jpeg'
import Img7 from '../../images/gallery/img7.jpeg'
import Img8 from '../../images/gallery/img8.jpeg'
import Img10 from '../../images/gallery/img10.jpeg'
import Img11 from '../../images/gallery/img11.jpeg'
import Img12 from '../../images/gallery/img12.jpeg'
import Img13 from '../../images/gallery/img13.jpeg'
import Img14 from '../../images/gallery/img14.jpeg'
const Images=[
    {
        img:Img1
    },
    {
        img:Img3
    },
    {
        img:Img6
    },
    
    {
        img:Img2
    },
    {
        img:Img7
    },
    {
        img:Img8
    },
    {
        img:Img5
    },
    {
        img:Img2
    },
    
    {
        img:Img7
    },
    {
        img:Img4    
    },
    {
        img:Img3
    },
	{
        img:Img14
    },
	{
        img:Img11
    },
	{
        img:Img12
    },
	{
        img:Img10
    },
	{
        img:Img13
    },
]
export default Images;