import styled from 'styled-components';

import { Row,Col } from 'react-bootstrap';

export const StyledRow=styled(Row)`

margin-left: 0px;
width:100%;
display: flex;
justify-content:center;


`;
export const StyledCol=styled(Col)`
margin-top:100px;
margin-bottom:20px;
display: flex;
justify-content:center;
`;