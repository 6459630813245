import styled from 'styled-components';
import { Row,Col } from 'react-bootstrap';

export const StyledCol=styled(Col)`
background:#ffffff;
margin:130px 0px 60px 0px;
padding:20px;
`;
export const StyledRow=styled(Row)`
margin-left:0;
width:100%;
justify-content:center;
`;